import ApiService from "@/core/services/ApiService";
import JwtService, {getTokenDetails, getUserRole, setLS} from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import {UserRoles} from "@/core/helpers/constants";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
  role?: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Return is authenticated user admin
   * @returns boolean
   */
  get isAuthenticatedAdmin() {
    //TODO remove after TODO complete
    return true;
    // TODO correct this check(users key) after user data 
    // return this.user.role === UserRoles.ADMIN;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = [];
    JwtService.saveToken(this.user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
        ...credentials
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/login", params)
        .then(({ data }) => {

          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](credentials) {
    const params = {
      ...credentials
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/change_password", params)
          .then(({ data }) => {
           // this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data);
            reject();
          });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }
  @Action
  [Actions.SIGNOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    window.location.reload();

  }
  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    const params = {
        ...payload,
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/forgot_password", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const params = {

      };
      ApiService.query("/verify-token", params as AxiosRequestConfig)
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data);
          if(data.accessToken) {
            this.context.commit(Mutations.SET_AUTH, data);
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHECK_TOKEN]() {
    if (JwtService.getToken() == undefined && JwtService.getRefreshToken()) {
      ApiService.setRefreshHeader();
      const params = {
      };
      ApiService.query("/auth/refresh-token", params as AxiosRequestConfig)
          .then(({ data }) => {
              this.context.commit(Mutations.SET_AUTH, data);
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data);
           this.context.commit(Mutations.PURGE_AUTH);
          });
    }
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
