import Cookies from "js-cookie";
const ID_TOKEN_KEY = "token" as string;
const REFRESH_TOKEN = "refresh_token" as string;
import jwtDecode, { JwtPayload } from "jwt-decode";
import AES from "@/common/AES.js";
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null | any => {
  return Cookies.get(ID_TOKEN_KEY)
  //return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getRefreshToken = (): string | null | any => {
  return Cookies.get(REFRESH_TOKEN)
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  const getTimeFromToken = getTokenDetails(token);
  const date = new Date(getTimeFromToken.exp*1000);
  Cookies.set(ID_TOKEN_KEY, token, { expires:date})
  //window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveRefreshToken = (token: string): void => {
  const getTimeFromToken = getTokenDetails(token);
  const date = new Date(getTimeFromToken.exp*1000);
  Cookies.set(REFRESH_TOKEN, token, { expires:date})
};

export const setLS = (key:any,value:any): void => {
  window.localStorage.setItem( key, AES.encrypt(value));
}
export const getLS = (key:any): any => {
  return AES.decrypt(  window.localStorage.getItem(key)  );
}
export const destroyLS = (key:any): void => {
  window.localStorage.removeItem(key);
}

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  Cookies.remove(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description gives information stored in the key
 */
export const getTokenDetails = (token): any | null => {
    if(token===null) {
      token = getToken()
    }
    return jwtDecode<JwtPayload>(token)
};
export const getUserRole = (): any => {
  const token:any = getToken();
  const jwtinfo:any = jwtDecode<JwtPayload>(token);
  const split:any = jwtinfo.sub.split(',');
  return split[3];
};
export default { getToken, saveToken, destroyToken,getTokenDetails,getUserRole,saveRefreshToken,getRefreshToken,setLS,getLS,destroyLS };
